<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-5 pt-md-5"  >
            <!-- Card stats -->
           <div class="py-4">
               <h6 class="h2 text-white d-inline-block mb-0">Darčekové poukazy</h6>
               <div class="text-right" style="float:right">
                          <a-button type="secondary"  @click="newVoucher">Vytvoriť voucher</a-button>
               </div>
           </div>
            <modal :show.sync="newVoucherVisible" :showClose="false" class="newvoucher" v-if="newVoucherVisible" :modalContentClasses="'new-voucher'" gradient="secondary">
   <h2 slot="header" class=" w-100 text-center text-uppercase" >Vytvoriť voucher</h2>
              <div class="row">
                  <div :class="demovoucher ? 'col-xl-4 col-lg-4' : 'col-xl-6 col-lg-6'">
                    <div class="mb-2">
                    <label class="form-control-label">Zvýhodnený pobyt</label>
                    <a-select style="width: 100%;" placeholder="Vyberte si zo zoznamu" :default-value="'0'" v-model="newExtra.package">
                        <a-select-option :value = "0">Nie je balíček</a-select-option>
                        <a-select-option v-for="pa in setup.options[setup.balicky.id]" :value = "pa.value">{{pa.label}}</a-select-option>
                    </a-select>
                </div>

                <div class="mb-2">
                    <label class="form-control-label">Izba</label>
                    <a-select style="width: 100%;" placeholder="Vyberte si zo zoznamu" v-model="newExtra.room">
                        <a-select-option v-for="pa in rooms" :value = "pa.value">{{pa.label}}</a-select-option>
                    </a-select>
                </div>

                      <div class="mb-2"  style="width:33%;float:left;">
                          <label class="form-control-label">Počet nocí</label>
                          <a-input-number alternative="" style="width:98%" input-classes="form-control-alternative" v-model="newExtra.nights"/>
                      </div>
                <div class="mb-2"  style="width:33%;float:left;">
                    <label class="form-control-label">Počet dospelých</label>
                    <a-input-number alternative="" :min="1" style="width:98%" input-classes="form-control-alternative" v-model="newExtra.adults"/>
                </div>
                <div class="mb-2"  style="width:33%;float:left;">
                    <label class="form-control-label">Počet detí</label>
                    <a-input-number alternative="" style="width:100%" input-classes="form-control-alternative" v-model="newExtra.kids"/>
                </div>
<div class="mb-2">

    <div class="mb-2"  style="width:50%;float:left;">
        <label class="form-control-label">Štýl</label>
        <a-input-number alternative="" style="width:98%" input-classes="form-control-alternative" v-model="newExtra.style"/>
    </div>

    <div class="mb-2"  style="width:50%;float:left;">
        <label class="form-control-label">Validita</label>
        <a-input alternative="" style="width:98%" input-classes="form-control-alternative" v-model="newExtra.valid"/>
    </div>
</div>
                  <div class="mb-2">
                      <label class="form-control-label">Zahrnuté služby</label>
                      <div v-for="(ser,index) in newExtra.services" class="service-in">  {{ser.count}} x {{ser.label}}<a-icon class="dynamic-delete-button date-delete" type="minus-circle-o" @click="() => remove(index)"/></div>
                      <div style="width:100%;display:inline-block;" class="voucher-services">
                          <a-input-number alternative="" :min="1" style="width:13%;float:left" input-classes="form-control-alternative" v-model="newExtra.count"/>
                          <a-select style="width: 64%;float:left" placeholder="Vyberte si zo zoznamu" default-value="0" v-model="newExtra.service">
                              <a-select-option v-for="pa in setup.options[setup.services.id]" :value = "pa.value">{{pa.label}}</a-select-option>
                          </a-select>
                          <a-button type="dashed" style="width:23%;float:left;height: 50px;" @click="addService">
                              <a-icon type="plus" /> Pridať
                          </a-button>

                      </div>
                  </div>
                  </div>
                      <div :class="demovoucher ? 'col-xl-4 col-lg-4' : 'col-xl-6 col-lg-6'">
                      <div class="mb-2">
                          <label class="form-control-label">Na meno</label>
                          <a-input alternative="" input-classes="form-control-alternative" v-model="newExtra.first_name"/>
                      </div>
                      <div class="mb-2" style="width:50%;float:left;">
                          <label class="form-control-label">Doručiť na email</label>
                          <a-input alternative="" type="email" input-classes="form-control-alternative" v-model="newExtra.email"/>
                      </div>
                      <div class="mb-2"  style="width:50%;float:left;">
                          <label class="form-control-label">Hodnota vouchera</label>
                          <a-input-number alternative="" style="width:100%" input-classes="form-control-alternative" v-model="newExtra.price"/>
                      </div>
                      <div class="mb-2">
                          <label class="form-control-label">Oslovenie</label>
                          <a-input alternative="" input-classes="form-control-alternative"  :placeholder="setup.giftcard_salutation.value"  v-model="newExtra.salutation"/>
                      </div>
                      <div class="mb-2">
                          <label class="form-control-label">Venovanie</label>
                          <a-textarea alternative="" input-classes="form-control-alternative" :placeholder="setup.giftcard_text.value"  v-model="newExtra.message"/>
                      </div>
                      <div class="mb-2">
                          <label class="form-control-label">Podpis</label>
                          <a-input alternative="" input-classes="form-control-alternative" :placeholder="setup.giftcard_signature.value" v-model="newExtra.signature"/>
                      </div>  </div>
                <div class="col-xl-4 col-lg-4">
                    <div v-if="newExtra" class="demovoucher">
                        <img :src="demovoucher">
                    </div>
            </div>
            </div>
                <template slot="footer"  style="padding-top:0;">
                    <a-button  type="white" class="ml-auto" @click="newVoucherVisible = false">Zatvoriť
                    </a-button>
                    <a-button type="secondary" @click="showexample"  v-if="newExtra.room || newExtra.package">Ukážka</a-button>
                    <a-button type="primary" @click="createVoucher" v-if="newExtra.room && newExtra.email && newExtra.first_name && newExtra.adults && newExtra.nights">Vytvoriť voucher</a-button>

                </template>
            </modal>

            <div class="row slim">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Poukazov za tento mesiac" v-if="loading === false"
                                type="gradient-green"
                                :sub-title="(parseInt(stats.namieru) + parseInt(stats.wellness) + parseInt(stats.pobytovy)).toString()"
                                icon="fal fa-file-certificate"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Pobyty na mieru" v-if="loading === false"
                                type="gradient-orange"
                                :sub-title="stats.namieru+''"
                                icon="fal fa-wand-magic"
                                class="mb-4 mb-xl-0"
                    >

                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Zvýhodnené pobyty" v-if="loading === false"
                                type="gradient-green"
                                :sub-title="stats.pobytovy+''"
                                icon="fal fa-badge-percent"
                                class="mb-4 mb-xl-0"
                    >

                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Wellness vouchere" v-if="loading === false"
                                type="gradient-info"
                                :sub-title="stats.wellness+''"
                                icon="fal fa-spa"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header blue border-0">
                            <div class="row align-items-center">
                              <div class="col"><h3 class="mb-0">Zakúpené darčekové poukazy</h3></div>
                                <div class="col extra " slot="extra">

                                    <a-input group class="text-right">
                                        <input type="text" class="form-control" placeholder="Zadajte kód" v-model="code" aria-label="Kód vouchera" aria-describedby="button-addon2">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" @click="checkCode()" id="button-addon2">Vyhľadať</button>
                                        </div>
                                    </a-input>
                                    <modal :show.sync="modals.searchCode" :showClose="false" v-if="responseModal" :gradient="parseInt(responseModal.status) === 1 ? 'danger' : 'secondary'"
                                           :modal-classes="parseInt(responseModal.status) === 1 ? 'modal-danger' : 'modal-secondary' ">
                                      <div v-if="responseModal.tv">  <h6 slot="header" class=" w-100 text-center text-uppercase" id="modal-title-default">
                                          <a-tag  v-if="responseModal.tv === 'pobytovy'" color="volcano" :key="responseModal.tv">pobytový voucher</a-tag>
                                          <a-tag  v-if="responseModal.tv === 'wellness'" color="geekblue" :key="responseModal.tv">wellness voucher</a-tag>
                                          <a-tag  v-if="responseModal.tv === 'product'" color="geekblue" :key="responseModal.tv">Produktový voucher</a-tag>
                                          <a-tag  v-if="responseModal.tv === 'namieru'" color="green" :key="responseModal.tv"> pobyt na mieru</a-tag></h6>
                                          <div v-if="parseInt(responseModal.status) === 1" class="text-center used">
                                              Kupón <a-tag color="red">{{code}}</a-tag> bol použitý {{formatDate(responseModal.voucher.changed)}}.
                                          </div>
                                        <div class="tr">
                                            <label class=" mr-4">Vytvorené</label> {{formatDate(responseModal.voucher.created)}}
                                        </div>
                                        <div class="tr" v-if="responseModal.product.package">
                                            <label class=" mr-4">Balíček</label> {{responseModal.product.package}}
                                        </div>
                                          <div class="tr" v-if="responseModal.product.room">
                                              <label class=" mr-4">Izba</label> {{responseModal.product.room}}
                                          </div>
                                          <div class="tr" v-if="responseModal.product.title">
                                              <label class=" mr-4">Produkt</label> {{responseModal.product.title}}
                                          </div>
                                          <div class="tr" v-if="responseModal.product.included">
                                              <label class=" mr-4"></label> {{responseModal.product.included}}
                                          </div>
                                          <div class="tr" v-if="responseModal.product.services">
                                              <label class=" mr-4">Služby</label> <div style="width:100%" v-for="see in responseModal.product.services">{{see}}</div>
                                          </div>
                                          <div class="tr" v-if="responseModal.product.adults">
                                              <label class=" mr-4">Osôb</label> {{responseModal.product.adults}}
                                          </div>
                                          <div class="tr" v-if="responseModal.product.kids">
                                              <label class=" mr-4">Detí</label> {{responseModal.product.kids}}
                                          </div>
                                        <div class="tr" v-if="responseModal.product.nights">
                                            <label class=" mr-4">Počet nocí</label> {{responseModal.product.nights}}
                                        </div>

<div class="tr" v-if="parseInt(responseModal.status) === 0">
    <label class=" mr-4">Darčekový poukaz uplatnený</label> <a-switch @change="onChange"/></div>
                                        <template slot="footer">
                                            <a-button type="primary" v-if="parseInt(responseModal.status) === 1">Uložiť zmeny</a-button>
                                            <a-button  type="white" class="ml-auto" @click="modals.searchCode = false;code = null">Zatvoriť
                                            </a-button>
                                        </template></div>
                                        <div v-else>
                                            <h6 slot="header" class=" w-100 text-center text-uppercase" id="modal-title-default">
                                                <a-tag  color="red" :key="responseModal.status"> Neexistujúci kupón</a-tag></h6>
                                            <div v-if="parseInt(responseModal.status) === 0" class="text-center ">
                                                Kupón <a-tag color="red">{{code}}</a-tag> neexistuje.
                                            </div>

                                        </div>
                                    </modal>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive slim">
                            <a-table
                                    :columns="columns"
                                    :dataSource="data"
                                    size="small"
                                    :pagination="pagination"
                                    :loading="loading"
                                    :rowKey="record => record.model"
                                    class="table-flush">
                                <div
                                        slot="filterDropdown"
                                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                                        style="padding: 8px"
                                >
                                    <a-input
                                            v-ant-ref="c => searchInput = c"
                                            :placeholder="`Search ${column.dataIndex}`"
                                            :value="selectedKeys[0]"
                                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                                            style="width: 188px; margin-bottom: 8px; display: block;"
                                    />
                                    <a-button
                                            type="primary"
                                            @click="() => handleSearch(selectedKeys, confirm)"
                                            icon="search"
                                            size="small"
                                            style="width: 90px; margin-right: 8px"
                                    >Search
                                    </a-button
                                    >
                                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px"
                                    >Reset
                                    </a-button
                                    >
                                </div>
                                <a-icon
                                        slot="filterIcon"
                                        slot-scope="filtered"
                                        type="search"
                                        :style="{ color: filtered ? '#108ee9' : undefined }"
                                />
                                <template slot="customRender" slot-scope="text">
      <span v-if="searchText">
        <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
          <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
          >{{fragment}}</mark
          >
          <template v-else>{{fragment}}</template>
        </template>
      </span>
                                    <template v-else>{{text}}</template>
                                </template>
                                <template slot="user_role" slot-scope="user">
                                    <a class="text-left text-lowercase" :href="'role/'+ role"
                                       v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span
                                            v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>

                                <template slot="used" slot-scope="status, record">
                                     <div v-if="parseInt(status) === 1">
                                         <a-tooltip  :title="formatDate(record.changed)"><a-tag color="green"><i class="fas fa-check"></i></a-tag></a-tooltip></div>
                                    <div v-else> <a-tooltip title="neuplatnený"><a-tag color="#dadada"><i class="fas fa-times"></i></a-tag></a-tooltip></div>
                                </template>

                                <template slot="products" slot-scope="price">
                                    {{Number(price).toFixed(2) + ' €'}}
                                </template>
                                <template slot="actions" slot-scope="id">

                                    <a-popconfirm placement="topRight" v-if="id"
                                                  @confirm="resendVoucher(id)"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si znova odoslať poukaz?</h5>
                                            Poukaz sa odošle v rovnakej podobe na uvedenú adresu
                                        </template>
                                        <a-button type="secondary"  icon="fal fa-share-square" textColor="default" title="Preposlať"></a-button>
                                    </a-popconfirm>
                                </template>
                                <template slot="vouchertype" slot-scope="type">
                                    <a-tag  v-if="type === 'pobytovy'" color="volcano" :key="type">     pobytový voucher</a-tag>
                                    <a-tag  v-if="type === 'wellness'" color="geekblue" :key="type"> wellness voucher</a-tag>
                                    <a-tag  v-if="type === 'product'" color="geekblue" :key="type"> produktový voucher</a-tag>
                                    <a-tag  v-if="type === 'namieru'" color="green" :key="type"> pobyt na mieru</a-tag>
                                </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="name" slot-scope="user">
                                    <base-dropdown class="dropdown" position="right">
                                        <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                          <template>
                                            <sidebar-item :link="{name: 'Detail', path: '/guest/' + user.uid}"/>
                                        </template>
                                    </base-dropdown>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"
    import {Button, Input, Icon, Select, Tag,Tooltip,Switch} from 'ant-design-vue';
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import moment from 'moment'
    import ATextarea from "ant-design-vue/es/input/TextArea";

    Vue.use(FormItem);

    export default {
        name: "giftcards",
        components: {
            ATextarea,
            "a-popconfirm": Popconfirm,
            "a-button": Button,
            "a-icon": Icon,
            "a-input": Input,
            "a-switch": Switch,
            Select,
            "a-tag": Tag,
            "a-tooltip": Tooltip,
        },
        data() {
            return {
                data: [],
                searchText: '',
                code: null,
                searchInput: null,
                newVoucherVisible:false,
                responseModal: false,
                stats:[],
                packages: {},
                rooms: {},
                setup: {},
                vars: {},
                demovoucher:'',
                colorsto: JSON.parse(localStorage.colors),
                example: "",
                newExtra:{'services':[]},
                modals: {
                    searchCode: false
                },
                roleops: [],
                pagination: {
                    defaultPageSize: 25
                },
                loading: true,
                columns: [
                    {
                        title: 'Meno',
                        dataIndex: 'name',
                        key: 'name',
                        width: '20%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon',
                            customRender: 'customRender',
                        },
                        onFilter: (value, record) => {
                            if (typeof record.name != 'undefined' && record.name) { return record.name.toString().toLowerCase().includes(value.toLowerCase()); } },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                       {
                        title: 'Email',
                        key: 'email',
                        dataIndex: 'email',
                    },
                    {
                        title: 'Telefón',
                        key: 'phone',
                        dataIndex: 'phone',
                    },
                    {
                        title: 'Vytvorené',
                        key: 'created',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'},
                    },
                    {
                        title: 'Typ vouchera',
                        dataIndex: 'tv',
                        key: 'voucher_type',
                        sortable: true,
                        width: '10%',
                        scopedSlots: {customRender: 'vouchertype'}
                    },
                    {
                        title: 'Uplatnený',
                        dataIndex: 'status',
                        key: 'used',
                        width: '2%',
                        scopedSlots: {customRender: 'used'}
                    },  {
                        title: 'Suma',
                        dataIndex: 'price',
                        key: 'price',
                        width: '10%',
                        align: 'right',
                        scopedSlots: {customRender: 'products'},
                    },
                    {
                        title: '',
                        dataIndex: 'id',
                        key: 'id',
                        width: '10%',
                        align: 'right',
                        scopedSlots: {customRender: 'actions'},
                    },
                ],
            };
        },

        mounted() {
            dataService.axiosFetch("variables","giftcards").then(results => {
                this.setup = results
            });
var self = this;
            dataService.axiosFetch("content","packages").then(results => {
                var roleops = [];
                roleops.push({"value" : '0' , "label" : 'Nie je balíček'});
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                self.packages = roleops;
            });
            dataService.axiosFetch("content","typy_izieb").then(results => {
                var roleops = [];
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
                self.rooms = roleops;
            });
            this.$nextTick(() => {
                this.fetch();

            })

        },
        methods: {
            handleOk()
            {
            },
            remove(index)
            {
                this.newExtra.services.splice(index, 1);
            },
            showexample()
            {
                dataService.axiosPost('getgiftcard', {custom:this.newExtra,realdeal:false}).then(results => {
                    this.demovoucher = results.data.data[0];
                });
            },
            addService()
            {
var tit = "";
                this.setup.options[this.setup.services.id].forEach(obj => {
                if(this.newExtra.service === obj.value)
                    tit = obj.label;
                })

          if(this.newExtra.count > 0)
              this.newExtra.services.push({count: this.newExtra.count,check: '1',service: this.newExtra.service,label: tit})
                this.newExtra.count = "";
                this.newExtra.service = "";
            },
            newVoucher()
            {
                this.newVoucherVisible = true;

            },
            checkCode(){
                dataService.axiosFetch('codeSearch',this.code).then(results => {
              if(results)
                  this.responseModal = results;
              else
                  this.responseModal.status = 0;
                    this.modals.searchCode = true;
                });
            },
            resendVoucher(id){
                dataService.axiosPost('rg', {id:id}).then(results => {


                });
            },
            createVoucher()
                {

                    this.loading = true;
                    this.newVoucherVisible = false;
                    dataService.axiosPost('getgiftcard', {custom:this.newExtra,realdeal:true}).then(results => {
                     this.fetch();

   });
                },
            onChange(t)
            {
                this.responseModal.voucher.status = t;
                dataService.axiosPost('markasused', this.responseModal.voucher).then(results => {
                    this.fetch();
      });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name).then(results => {
                    this.data = results.orders;
                    this.stats = results.stats;
                    this.vars = results.vars;
                    this.loading = false;
                    this.newVoucherVisible = false;
                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }
.demovoucher
{max-width:100%;}
.demovoucher img
{width:100%;}

    .highlight {
        background-color: rgb(255, 192, 105);
        padding: 0px;
    }
.new-voucher .modal-body
{background-color:#f2f4f9;}

    .tr
    {width:100%;display:inline-block;text-align:right;font-weight:200;font-size:13px;margin-bottom:10px;padding-bottom:10px;border-bottom: 1px solid #f1f1f1}
.tr label{float:left;font-weight:bold;font-size:15px}

    .date-delete
    {float:right}
    .slim table tr:nth-child(odd) td{

        background-color:#eff7fe;
    }

.used
{
    margin: 32px;
}
    .service-in
    {font-size:13px;padding:4px;}
.extra .form-control
{display:inline;width:auto;
border-top-right-radius: 0;
    border-bottom-right-radius: 0}
.extra .input-group-append
{float:right}
    .extra .input-group-append button
    {border-top-left-radius: 0;
    border-bottom-left-radius: 0}

#app .newvoucher .modal-dialog
{max-width:75%;width:75%;}


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }
    .ant-input-number-input
    {height:50px;}
    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }
.modal{    z-index: 99999999999;}
    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
    .newvoucher .modal-dialog {
        width: 45%;
        max-width:900px;
    }
.newvoucher .ant-select-selection--single
{    height: 32px; }
    .newvoucher .ant-select-selection__placeholder,.newvoucher .ant-select-search__field__placeholder {
        position: absolute;
        top: 50%;
        right: 9px;
        left: 0;
        max-width: 100%;
        height: 20px;
        margin-top: -10px;
        overflow: hidden;
        color: #bfbfbf;
        line-height: 20px;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
    }
    .ant-select-dropdown
    {z-index:9999999999;}
    .voucher-services .anticon-plus
    {
        position: relative;
        top: -3px;
        left: -2px;}
</style>
